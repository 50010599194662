body {
  padding-top: 75px;
  padding-bottom: 20px;
  background-color: #CDDEA9;
  background-repeat: no-repeat;
  background-attachment:fixed;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.clearFix {
  clear: both;
}

.conferenceBody {
  background-color:rgb(187, 187, 187) !important;
}
.conferenceBody h4 {
  font-weight: bold;
  margin-top: .2em;
}
.section h1 {
  margin-top: 20px;
}
h2 {
  margin-top: .5em;
}

h3 {
  margin-top: .5em;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 2em;
}
h5 {
  padding-top: 10px;
}

.header {
  margin-bottom: 20px;
}
.footer {
  margin-top: 20px;
}
.float-left {
  float: left;
  margin: 10px;
}
a, a:visited{
  color: black;
}
a:hover, a:active {
  color: #6D9E02;
  text-decoration: none;
}
.btn-success,
.btn-success:visited {
  color: white;
}
.btn-contrast{
  background-color: white;
  color:#6D9E02;
  font-weight: bold;
  font-size: 14px;
  border-radius: 2px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.icon {
  max-width: 80px;
  padding: 5px;
  float: left;
  margin-left: 20px;
  margin-right: 10px;
}
.smallIcon {
  max-width: 45px;
  padding: 5px;
  float: left;
  margin-left: 20px;
  margin-right: 10px;
}
.titleLink {
  text-decoration: underline;
}


.thankYouMessage {
  text-align: center;
  padding: 25px 0px;
}
.halfSection {
  width: calc(50% - 2em);
  float: left;
  background-color: white;
  border-radius: 6px;
  margin: 1em;
  padding: 15px;
  display: block;
}

.section {
  background-color: white;
  padding: 20px;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 25px;
}
.partnerLogos img{
  width: 75%;
  max-width: 220px;
  padding: 15px;
}

.feature {
  border: 2px solid #6D9E02;
  border-radius: 6px;
  margin-top: 15px;
}
.feature video {
  border: 1px solid gray;
}
.banner {
  background-color:  #6D9E02;
  color: white;
  padding: 0;
  margin-bottom: 1em;
  border-radius: 3px;
}
.banner img {
  max-height: 100%;
  max-width: 100%;
}
.bannerContent {
  padding: 20px;
}
.bannerImg {
  text-align: right;
  padding: 0;
  max-height: 350px;
  min-height: 250px;
  height: 100vh;
}
#whiteBox {
  background-color: #6D9E02;
  color: white;
  padding: 20px;
  margin-top: 1em;
  border-radius: 3px;
}
.blueGradient {
  background: linear-gradient(90deg, rgba(32,124,171,1) 0%, rgba(9,39,99,1) 100%);
  padding: 1em;
  color: white;
  font-weight: bold;
}
.blueGradientITN {
  background: linear-gradient(90deg,rgba(27,45,63,1) 0%, rgba(55,152,196,1) 50%, rgba(26,31,42,1) 100%);
  padding: 1em;
  color: white;
  font-weight: bold;
}
.PinktoPurpleGradient {
  background: linear-gradient(90deg, rgba(236,23,128,1) 0%, rgba(141, 62, 245,1) 100%);
  padding: 1em;
  color: white;
  font-weight: bold;
  min-height: 30px;
}
.homepageConferenceBanner {
  background: url("/images/FlowersBG.png");
  background-repeat: no-repeat;
  background-size:cover;
  background-position-x: right;
  margin: 10px;
}

a>.homepageConferenceBannerContainer:hover {
  color: black !important;
}

.conferenceFlowers {
  float: right;
  width: 50%;
}
.ITN2022Banner {
  background: url("/images/ITNConnect-blank-banner.png");
  min-height: 120px;
  color: white;
}
.ITN2023Banner {
  background-color: #25346D;
  min-height: 120px;
  color: white;
}
.ITN2023BannerBottom {
  background: linear-gradient(90deg, rgb(6, 177, 225) 0%, rgb(140, 200, 97) 100%);
  min-height: 120px;
  color: white;
}
.purpleBG {
  background-color: rgba(78,43,167,1);
  padding: 1em;
  color: white;
  font-weight: bold;
}
.whiteBG {
  background-color: white;
}
.greenBG {
  background-color:#6D9E02;
  color: white;
  padding: 10px 0px 10px 0px;
}
.overlayGradientBox {
  background: linear-gradient(90deg, rgba(9,39,99,0.80) 0%, rgba(32,124,171,0.80) 100%);
  border-radius: 8px;
  border:rgb(9,39,99) solid 1.5px;
  padding: 15px;
  margin: 20px;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
}
.ITNCDetails {
  padding: 15px;
  margin: 20px;
  color: white;
  font-weight: bold;
  font-size: 1em;
}
.solidText {
  opacity: 100% !important;
}
.noBullet {
  list-style-type: none;
}
/*#demoVideo {
  margin-top: 0px;
  margin-bottom: 20px;
}*/
#demoVideo img {
  width: 100%;
}
.video div{
  box-align: top;
}
#videoWrap {
  color: white;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 100%;
  padding: 30px 20px;
}
#videoWrapITN {
  color: white;
  background: url("/images/ITNConnect-blank-banner-tall.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 20px;
}
.logoStyle {
  width: 100%;
  vertical-align: top;
  padding: 5px;
}
.smallLogoStyle {
  width: 100%;
  max-width: 150px;
  vertical-align: top;
  margin-top: 5px;
  padding: 10px;
}
#conferenceDetails {
  text-align: right;
  color: white;
  font-weight: bold;
  margin-bottom: 0.1em;
}
.conferenceBG {
  background: url("/images/CP-BG-image.jpg");
  background-repeat: no-repeat;
}
.conferenceBGITN {
  background: url("/images/ITNC-BG-Image.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: center;
  min-height: 385px;
}
.conferenceBGITNSecure {
  background-color: #25346D;
  color: white;
}
.conferenceBGPax8Top {
  background-color: black;
  color: white;
}
.conferenceBGITNSecureBottom {
  background: url("/images/itnsecure-hero-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  min-height: 385px;
}
.conferenceBGPax8Beyond {
  background: url("/images/Pax8BeyondBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  min-height: 385px;
}
.conferenceBGConnectIT {
  background: url("/images/ConnectITBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  min-height: 385px;
}
.conferenceBGKaseyaConnect23 {
  background: url("/images/KaseyaConnectBG2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  min-height: 385px;
}
.conferenceBGKaseyaConnect23Top {
  background: url("/images/KaseyaConnectTopBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  color: white;
  padding: 25px;
}

.conferenceBGAsciiEdge23 {
  background: url("/images/BrainBGlong.png");
  background-repeat: no-repeat;
  background-size:contain;
  background-position-x: right;
  color: white;
  padding: 25px;
  background-color: #101010;
}

.conferenceBGChannelCon23 {
  background: url("/images/ChannelConHeader.png");
  background-repeat: no-repeat;
  background-size:cover;
  background-position-x: right;
  color: white;
  padding: 25px;
}
.conferenceBGChannelCon23bottom {
  background: url("/images/ChannelConHeader.png");
  background-repeat: no-repeat;
  background-size:cover;
  background-position-x: right;
  color: black;
  padding: 25px;
  font-weight: bold;
  font-size: 1em;
}

.conferenceBGAsciiEdge23Top {
  background-color: #101010;
  color: white;
  padding: 20px;
}

.divider {
  margin: 45px 25px 25px 25px;
  border: 1px solid white;
}
.priceFeatureColumn>h5 {
  font-size: 1em !important;
}
.priceColumnHeader {
  background-color: white;
  border-top: none;
  font-size: 1em;
}
.priceHeaderRow {
  background-color: transparent;
  border-top: none;
  text-align: center;
}
.priceRow>td {
  font-weight: bold;
  font-size: 1em;
  color: green;
}
.comparison {
  padding: 10px;
}
.comparisonData {
  background-color: white;
  border-radius: 3px;
  height: 100%;
  padding-bottom: 30px;
}
.comparisonFooter {
  position: absolute;
  bottom: 5px;
  width: 100%;
}
.comparisonFooter>div {
  margin: 0 auto;
  text-align: center;
}
.comparisonHeader {
  background-color:#6D9E02;
  color: white;
}
.comparisonHeader>h2 {
  padding: 10px;
}

.comparisonData>ul {
  text-align: left;
}

.notIncluded {
  color: red;
  font-weight: bold;
}

.bigGreenText {
  color:#6D9E02;
  font-weight: bold;
  font-size: 1.3em;
}

.addnlCost {
  color: black;
  font-weight: normal;
  font-size: 0.8em;
}

.priceRow:nth-child(odd)>.priceFeatureColumn {
  color: white;
  background-color:#6D9E02;
  text-align: left;
  border-top: none;

}
.priceRow:nth-child(even)>.priceFeatureColumn {
  color: white;
  background-color:#7EA319;
  text-align: left;
  border-top: none;

}
.priceRow:nth-child(odd) {
  background-color:#fff;
  text-align: center;
  border-top: none;
}

.priceRow:nth-child(even) {
  background-color:#f7f7f7;
  text-align: center;
  border-top: none;
}
.priceTable {
  width: 100%;
}

.priceTable>tbody>tr:first-of-type>td:first-of-type, .priceTable>thead>tr:first-of-type>th:first-of-type {
  background-clip: padding-box;
  border-top-left-radius: 3px;
}

.priceTable>thead>tr:first-of-type>th:last-of-type {
  background-clip: padding-box;
  border-top-right-radius: 3px;
}

.priceRow:last-of-type>td:first-of-type {
  background-clip: padding-box;
  border-bottom-left-radius: 3px;
}

.priceRow:last-of-type>td:last-of-type {
  background-clip: padding-box;
  border-bottom-right-radius: 3px;
}

.priceTable>thead>tr>td, .priceTable>thead>tr>th {
  border: none;
}

.priceTable>td {
  padding: 1em;
}

.priceDisplay {
  background-color: white;
  margin: 20px 0px;
  border-radius: 3px;
  padding-bottom: 10px;
}
.priceDisplay h1 {
  font-size: 1.2em;
  margin-top: 0.5em;
  font-weight: bold;
}
.priceStyle {
  font-size: 1.6em;
  font-weight: bold;
  background-color: #6D9E02;
  color: #fff;
  border-radius: 3px;
  text-align: left;
  padding-top: 0.5em;
}
.priceMod {
  font-size: 0.5em;
  font-weight: normal;
}
.walkthrough {
  min-height: 305px;
}
.walkthrough img {
  max-width: 150px;
  margin: 0 auto;
}
.detail {
  padding: 2em 1em;
  border-top: 1px solid white;
  background-color: white;
  border-radius: 3px;
  
}
.detail:nth-child(odd) {
  background-color: #f7f7f7;
}
.detail-col img {
  max-height: 100%;
  max-width: 100%;
  box-shadow: 1px 1px 16px rgb(187, 187, 187);
  border-radius: 3px;
}
.card-title {
  text-align: center;
}
.role {
  border: 3px solid #6D9E02;
  border-radius: 5px;
  margin-top: 60px !important;
  min-height: 250px;
}
.role img {
  max-width: 75px;
  margin: 0 auto;
  margin-top: -40px;
}
.bios img {
  padding: 20px;
  width: 100%;
  border-radius: 50%;
}
.bios {
  background-color: white;
  margin: 1em auto;
  margin-left: 100px;
  text-align: left;
  padding: 0 !important;
  border-radius: 3px;
}
.bios-col {
  padding: 0;
  margin-left: -120px;
}
.bios-content {
  margin-left:0px;
  width: 100%;
}
.card {
  margin: 1em auto;
  max-width: 280px;
  position: relative; 
}
.card-title {
  font-weight: bold;
  font-size: 1.1em;
}
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

nav.navbar {
  border-bottom: 1px;
  border-color: black;
  padding: 0;
}

.centered {
  text-align:center;
}

.body-content {
  padding-left: 15px;
  padding-right:15px
}

.carousel-caption p {
  font-size: 20px;
  line-height: 1.4
}

.carousel-inner .item img[src$=".svg"] {
  width:100%
}

#qrCode {
  margin:15px
}

@media screen and (max-width: 575px) {
  .bios img {
    margin-top: -100px;
  }
  .bios {
    margin: 120px 0;
  }
  .bios-col{
    margin: 0px;
    text-align: center;
  }
  body, .container {
    padding-left: 5px;
    padding-right: 5px;
    font-size: 95%;
  }
  .mobileCentered {
    text-align: center !important;
  }
  
}
@media screen and (max-width: 1200px) {
  .conferenceBGAsciiEdge23 {
    background: url("/images/BrainBGtall.png");
    background-repeat: no-repeat;
    background-position-x: bottom;
    background-position-y: right;
    background-color:#101010;
  }
}
@media screen and (max-width: 991px) {
  

  .conferenceBGITN {
    background: url("/images/ITNC-BG-Image-cropped.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    min-height: auto;
  }
  .personas {
      display:none
  }
  .bios img {
    margin-top: -140px;
    padding: 0 30px;
    max-width: 400px;
  }
  .bios {
    margin-top: 150px;
    margin-bottom: 160px;
    margin-left: 0px;
  }
  .bios-col{
    margin: 0px;
    text-align: center;
  }
  .icon {
    text-align: center;
    margin-left: 40px;
  }
  .intro {
    text-align: center;
  }
  .halfSection {
    width: 100%;
    float: none;
    margin: 1em 0;
  }
}
.embed-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
  box-shadow: 0px 0px 12px #333;
  margin-top: 20px;
} 

.video-embed-container iframe, .embed-container iframe, .embed-container object, .embed-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
}

.video {
  background-color: #fff;
  margin-bottom: 20px;
}

.video-embed-container {
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; 
  overflow: hidden; 
  max-width: 100%; 
  margin-top: 0px;
}

.videoDetails {
  padding: 15px;
}

.mainLogoLink {
  width: 100%;
}
#navLogo {
  height: 50px;
}
.mainNav .nav-link {
  padding-left: 1em !important;
}
#mainLogo2 {
  height: 50px;
  background-image: url("/images/mainLogo.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
  background-size:contain;
}

@media only screen and (min-width: 350px) {
  #mainLogo2 {
      height: 55px;
      background-image: url("/images/longLogo.png");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-size: contain;
      margin:10px
  }
}

nav.navbar {
  min-height: 50px;
  background-color:#fff
}

.left {
  text-align:left !important
}
.right {
  text-align: right;
}

.jumbotron {
  padding: 25px;
  margin-top: 15px;
  font-size: 12pt;
  line-height:1.4em
}

.aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.aligner-item-bottom {
  align-self: flex-end;
}

#channelPartnersBanner {
  font-size: 1.8em;
  font-weight: bold;
  color:white;
  position: relative;
}
#channelPartnersBanner img {
  width: 100%;
  margin-top: 1em;
  border-radius: 3px;
}

.fine-print {
  font-size:9pt
}

.personas div {
  float:left
}

.personaImage {
  margin-top: 15px;
  width: 120px;
  float:left
}

.personaImageRight {
  margin-top: 15px;
  width: 120px;
  float: right;
  margin-right:20px
}

.bubble {
  margin-top: 15px;
  display: block;
  position: relative;
  width: calc(90% - 120px);
  background-color: #fff;
  padding: 20px;
  color: #333;
  border-radius: 5px;
  margin-left: 15px;
  float:left
}

.bubble:after {
  content: "";
  display: block;
  position: absolute;
  left: -15px;
  top: 35px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right:15px solid #fff
}

.bubbleRight {
  margin-top: 15px;
  display: block;
  position: relative;
  width: calc(90% - 120px);
  background-color: #fff;
  padding: 20px;
  color: #333;
  border-radius: 5px;
  margin-left: 15px;
  float:right
}

.bubbleRight:after {
  content: "";
  display: block;
  position: absolute;
  right: -15px;
  top: 35px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left:15px solid #fff
}

.clear-float {
  clear:both
}

.clear-left {
  clear:left
}

.clear-right {
  clear: right
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 0;
  border: 0;
  border-top: 1px solid #eee;
}

.spin {
  -webkit-animation-name: spin;
    -webkit-animation-duration: 60s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 60s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 60s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    
    animation-name: spin;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-moz-keyframes spin {
  0% { 
    -moz-transform: rotate(0deg); 
  }
  50% { 
    -moz-transform: rotate(0deg); 
  }
  100% {
    -moz-transform: rotate(3600deg);
  }
}
@-webkit-keyframes spin {
  0% { 
    -webkit-transform: rotate(0deg); 
  }
  50% { 
    -webkit-transform: rotate(0deg); 
  }
  100% {
    -webkit-transform: rotate(3600deg);
  }
}
@keyframes spin {
  0% { 
    transform: rotate(0deg); 
  }
  50% { 
    transform: rotate(0deg); 
  }
  100% {
    transform: rotate(3600deg);
  }
}